* {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
}

.opacity-100 {
  opacity: 1;
}

.opacity-90 {
  opacity: .9;
}

.opacity-80 {
  opacity: .8;
}

.opacity-70 {
  opacity: .7;
}

.opacity-60 {
  opacity: .6;
}

.opacity-50 {
  opacity: .5;
}

.opacity-40 {
  opacity: .4;
}

.opacity-30 {
  opacity: .3;
}

.opacity-20 {
  opacity: .2;
}

.opacity-10 {
  opacity: .1;
}

.opacity-0 {
  opacity: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  border: none;
  border-top: 1px solid var(--bs-gray-300);
}

.table {
  cursor: default;
}

.position-absolute-all {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-body {
  display: block;
  background-color: #000000c4;
  position: fixed;
  top: 0;
  left: -100vw;
  bottom: 0;
  width: 100%;
  z-index: 100000;
  /* transition: .1s; */
  opacity: 0;
}

.header-menu {
  position: absolute;
  top: 0;
  left: -500px;
  bottom: 0;
  width: 250px;
  background-color: #173461;
  transition: .3s;
  color: #ffffff;
  border-right: 1px solid #1a3c6f;
}

.header-menu .header-header {
  height: 50px;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #1a3c6f;
}

.header-menu a {
  color: #ffffff;
}

.header-menu .header-link {
  display: block;
  padding: .5rem 1rem;
}

.header-menu .header-link.active {
  color: #ffbbbb;
  background-color: #183054;
}

.header-menu .header-link:hover {
  background-color: #1a3c6f;
}

.header-body.show {
  display: block;
  left: 0;
  opacity: 1;
}

.header-body.show>.header-menu {
  left: 0;
}