.body-header {
    position: sticky;
    top: 0;
    height: 50px;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--bs-gray-200);
    background-color: var(--bs-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
}

.body-header>*:not(:last-child) {
    margin-right: 1rem;
}

.header-link.active {
    color: red;
}

.header-table-parts td {
    background-color: rgba(34, 36, 38, 0.1);
}

.upload-zone .drag-zone-files {
    width: 100%;
    height: 50px;
    border-radius: .5rem;
    border: 1px dashed var(--bs-gray-400);
    color: var(--bs-gray-400);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-zone .drag-zone-files:hover {
    border-color: var(--bs-gray-600);
    color: var(--bs-gray-600);
}

.upload-zone .is-disabled {
    border: none !important;
}

.upload-zone .is-disabled .drag-zone-files,
.upload-zone .is-disabled .drag-zone-files:hover {
    border-color: var(--bs-gray-300);
    color: var(--bs-gray-800);
    cursor: no-drop;
}

.upload-zone>label>div:not(.drag-zone-files) {
    background-color: var(--bs-gray-100);
    border: 1px dashed var(--bs-gray-600);
    opacity: 1;
}

.upload-zone>label>div:not(.drag-zone-files) * {
    opacity: .5;
}

.file-list>div {
    padding: .25rem 1rem;
}

.file-list>div:hover {
    background-color: var(--bs-gray-100);
}

.file-list>div:not(:last-child) {
    border-bottom: 1px solid var(--bs-gray-200);
}


.file-list>.file-error {
    color: brown;
    background-color: #fff3f3;
}

.file-list>.file-error:hover {
    background-color: #ffe6e6;
}

.file-list>.file-deleted .file-type-icon {
    opacity: .5;
}

.file-list>.file-deleted .file-name {
    text-decoration: line-through;
    opacity: .6;
}

.file-upload-progress {
    position: relative;
    width: 80px;
    height: 5px;
    background-color: var(--bs-gray-300);
    border-radius: .25rem;
    overflow: hidden;
}

.file-upload-progress-bar {
    background-color: var(--bs-green);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: .1s;
}

.download-file-name {
    cursor: pointer;
    text-decoration: none !important;
}

.download-file-name:hover {
    text-decoration: underline !important;
}

.income-table-row:hover {
    background-color: var(--bs-gray-100);
}

.income-table-row.not-free {
    background-color: #baffb8;
}

.income-table-row.not-free:hover {
    background-color: #9fffa2;
}

.income-table-row.in-archive {
    background-color: #fffd83;
}

.income-table-row.in-archive:hover {
    background-color: #fffd76;
}

.income-table-row.overdue {
    background-color: #ffd2d2;
}

.income-table-row.overdue:hover {
    background-color: #ffc0c0;
}

.pay-row.no-pay>*:not(.not-colors) {
    background-color: #da3939 !important;
    color: #ffffff !important;
}

.pay-row.one-pay>*:not(.not-colors) {
    background-color: #d0d0d0 !important;
    /* color: #ffffff !important; */
}

.k-segment {
    box-shadow: none !important;
    border-color: var(--bs-gray-200) !important;
}

.pay-row-tenant {
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    cursor: default;
}

.pay-row-tenant:not(:last-child) {
    border-bottom: 1px solid var(--bs-gray-200);
}

.pay-row-type-one {
    background-color: #89f489;
    color: #004300;
}

.pay-row-no-pay {
    color: #ffffff;
    background-color: var(--bs-red);
}

.pay-row-tenant .buttons-row {
    /* display: none; */
    transition: .2s;
}

.pay-row-tenant:hover .buttons-row {
    display: flex;
    transition: .2s;
}

.pay-row-tenant.deleted-pay .for-name {
    text-decoration: line-through;
}

.grid-absolute {
    position: absolute;
    left: 10px;
    right: 10px;
    top: -10px;
    border-color: var(--bs-gray-200);
}

.grid-data-tenant>*:hover {
    background-color: var(--bs-gray-100);
    cursor: default;
}

.search-select .menu {
    margin-top: 35px !important;
    left: -1px !important;
    right: 0 !important;
}

.celled-cashbox td {
    border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.celled-cashbox td:last-child {
    border-right: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.cashbox-date-row td,
.cashbox-date-row.active td {
    background: #d9e1f2 !important;
}

.additional-service-row {
    padding: .25rem 1rem;
    cursor: default;
}

.additional-service-row:hover {
    background: var(--bs-gray-100);
    border-radius: .25rem;
}