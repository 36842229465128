.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
}

.spinner.mini {
    margin: -7px 0 0 -7px;
    width: 14px;
    height: 14px;
}

.spinner.tiny {
    margin: -10px 0 0 -10px;
    width: 20px;
    height: 20px;
}

.spinner.small {
    margin: -15px 0 0 -15px;
    width: 30px;
    height: 30px;
}

.spinner.large {
    margin: -30px 0 0 -30px;
    width: 60px;
    height: 60px;
}

.spinner.big {
    margin: -35px 0 0 -35px;
    width: 70px;
    height: 70px;
}

.spinner .path {
    stroke: #198754;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}